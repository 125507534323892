//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  props: {
    visibilityProp: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      pages: ({ pagesStore: { pages } }) => pages,
    }),
  },
};
